import React from 'react';
import { array, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { propTypes } from '../../../util/types';
import { ListingCard, PaginationLinks } from '../../../components';
import css from './SearchResultsPanel.module.css';
import filterListings from './filterListings';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    isMapVariant,
  } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pubJoy = queryParams.get('pub_joy');
  const px = queryParams.get('px');

  const classes = classNames(rootClassName || css.root, className);
  const isTeamBuilding = location.pathname.startsWith('/ts');

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        '14vw',
      ].join(', ');
    }
  };


  const filteredListings = filterListings(listings, px);
  
  return (
    <div className={classes}>
      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>

        {isTeamBuilding
          ? filteredListings
              .filter(ll => ll.attributes.publicData?.listingType === 'teambuilding')
              .map(l => (
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes(isMapVariant)}
                  setActiveListing={setActiveListing}
                  min={l.attributes.publicData?.min}
                  max={l.attributes.publicData?.max}
                  isTeamBuilding={l.attributes.publicData.listingType}
                />
              ))
          : filteredListings
              .filter(ll => ll.attributes.publicData?.listingType !== 'teambuilding')
              .map(l => (
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes(isMapVariant)}
                  setActiveListing={setActiveListing}
                  min={l.attributes.publicData?.min}
                  max={l.attributes.publicData?.max}
                  isTeamBuilding={l.attributes.publicData.listingType}
                />
              ))}
        {props.children}
      </div>
      {pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName={isTeamBuilding ? "teamSearchPage" : "SearchPage"}
          pageSearchParams={search}
          pagination={pagination}
        />
      ) : null}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

export default SearchResultsPanel;
